import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img1 from "../../images/westlink/1.jpg"
import img2 from "../../images/westlink/2.jpg"
import img4 from "../../images/westlink/4.jpg"
import img5 from "../../images/westlink/5.jpg"


// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;




export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    westlink.forum is the newest conference room in Zürich.
                    <p/>
                    <a href="http://www.tbsagency.com" target="_blank">TBS</a> did the branding, <a href="http://www.kilokilo.ch" target="_blank">KiloKilo</a> did the design of their website, and <LinkABOUT location={location}>I</LinkABOUT> wrote the text in English and German.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </English>

         <Deutsch Language={location.search}>
              <Type>

                    westlink.forum ist der modernste und minimalistischste Konferenzraum in Zürich.
                    <p/>
                    <a href="http://www.tbsagency.com" target="_blank">TBS</a> entwickelte das Branding, <a href="http://www.kilokilo.ch" target="_blank">KiloKilo</a> die Webseite, und <LinkABOUT location={location}>Ich</LinkABOUT> schrieb den Text auf Englisch und Deutsch.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </Deutsch>

    </div>

    <Element name="test1"></Element>


    <img src={img1}/>





      <div className="comment">
        <English Language={location.search}>
              <Type>
              Located in a new building complex, the westlink conference room features modern architecture and state of the art equipment.
              </Type>
        </English>

         <Deutsch Language={location.search}>
              <Type>

                Der westlink Konferenzraum ist in einem neuen Gebäudekomplex untergebracht und bringt zeitgemässe, minimalistische Architektur und modernste Ausrüstung.

              </Type>
        </Deutsch>
        
      </div>


      <img src={img2}/>







      <div className="comment">
        <English Language={location.search}>
              <Type>

              Writing at great length how modern, quiet and minimalistic - really minimalistic - and uncomplicated this conference room was would have been a performative contradiction.

              </Type>
        </English>

         <Deutsch Language={location.search}>
              <Type>

                Endlos darüber zu schreiben, wie modern, minimalistisch und unkompliziert der Konferenzraum ist, wäre ein performativer Widerspruch gewesen.

              </Type>
        </Deutsch>

      </div>

      <img src={img4}/>


      <div className="comment">
        <English Language={location.search}>
              <Type>

              So instead, the language I developed for the project reflected the modern minimalism with fewer adjectives and the flexibility with dynamic verbs.

              </Type>
        </English>

         <Deutsch Language={location.search}>
              <Type>

                Stattdessen reflektierte die Sprache, welche ich für das Projekt entwickelte den modernen Minimalismus mit sprachlichem Minimalismus: wenig Adjektive, und wenig Wörter überhaupt.

              </Type>
        </Deutsch>

      </div>


      <img src={img5}/>






      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
